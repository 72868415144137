// 下载
const userAgent = navigator.userAgent;
export const onDownload = () => {
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    // 是iOS设备
    window.open(window.download.ios, '_blank');
  } else if (/android/i.test(userAgent)) {
    // 是Android设备
    window.open(window.download.android, '_blank');
  } else {
    window.open(window.download.android, '_blank');
  }
};
